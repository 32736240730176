/* настройки брендв */
.brand-avatar-container {
  position: relative;
  width: 150px;
  height: 150px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.brand-avatar-container:hover {
  transform: scale(1.05);
}

.brand-avatar-container.selected {
  transform: scale(1.1);
}

.brand-avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.brand-avatar-container svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.brand-avatar-container {
  width: 112px;
  height: 112px;
}  
/* настройки брендв */

@keyframes confetti-fall {
  0% { transform: translateY(-100vh) rotate(0deg); }
  100% { transform: translateY(100vh) rotate(360deg); }
}

.animate-confetti {
  position: absolute;
  animation: confetti-fall linear infinite;
}

.single-select-container {
  max-width: 100%;
  overflow: hidden;
}

.single-select-container .selected-value {
  width: 24px;
  height: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-select-container .selected-value img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* Нижнее меню */
.fixed {
  position: fixed;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.border-t {
  border-top-width: 1px;
}

.pb-16 {
  padding-bottom: 4rem;
}
/* Нижнее меню */

/* Стили для главного меню */
.main-menu {
  background-color: rgb(0, 0, 0);
}

.main-menu-item {
  background-color: transparent !important;
  transition: all 0.3s ease;
}

.main-menu-item:hover, .main-menu-item:focus {
  background-color: transparent !important;
}

.main-menu-item:hover .menu-icon,
.main-menu-item:hover .menu-text,
.main-menu-item:focus .menu-icon,
.main-menu-item:focus .menu-text {
  color: #ffffff;
}

.main-menu-item.active {
  color: #e0ff89;
  filter: drop-shadow(0 0 5px #e0ff89);
}

.main-menu-item.active .menu-icon,
.main-menu-item.active .menu-text {
  color: #e0ff89;
  filter: drop-shadow(0 0 5px #e0ff89);
}
/* Стили для главного меню */

/* скрытие скролла в меню */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.overflow-y-auto {
    overflow-y: auto;
    padding-bottom: 20px;
}
/* скрытие скролла в меню */

/* Глобальные настройки прокрутки */
html, body {
  overscroll-behavior-y: none; /* Предотвращает эффект "пружины" на iOS */
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Обеспечение прокрутки на мобильных устройствах */
@media (max-width: 768px) {
  #root, .app-container {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; /* Плавная прокрутка на iOS */
  }
  
  /* Дополнительный отступ для контента, чтобы он был виден над нижним меню */
  .main-content {
    padding-bottom: 100px !important;
  }
}

/* Исправление отображения модальных окон */
.fixed-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  overflow-y: auto;
}
/* Глобальные настройки прокрутки */

/* "Эффекты для рангов" */
@keyframes float {
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
}

@keyframes shine {
  0% { background-position: -100% 0; }
  100% { background-position: 200% 0; }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

.animate-shine {
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
  background-size: 200% 100%;
  animation: shine 2s linear infinite;
}
/* "Эффекты для рангов" */

/* "Эффекты для рангов 2" */
@keyframes titlePulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.7; }
  }
  
  @keyframes titleWave {
    0% { transform: translate(0px, 0px); }
    25% { transform: translate(2px, -2px); }
    50% { transform: translate(0px, -4px); }
    75% { transform: translate(-2px, -2px); }
    100% { transform: translate(0px, 0px); }
  }
  
  @keyframes titleShine {
    0% { background-position: 0% center; }
    100% { background-position: 200% center; }
  }
  
  @keyframes float {
    0%, 100% { transform: translateY(0px); }
    50% { transform: translateY(-8px); }
  }
  
  @keyframes pulse-bg {
    0%, 100% { opacity: 0.7; }
    50% { opacity: 0.4; }
  }
  
  @keyframes fade-bg {
    0%, 100% { opacity: 0.7; }
    50% { opacity: 0; }
  }
  
  @keyframes wave-bg {
    0% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0); }
  }
  
  @keyframes pulse-infinite {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }
  
  @keyframes rotate-infinite {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes shine-infinite {
    0%, 100% { opacity: 0.5; }
    25% { opacity: 0.8; }
    50% { opacity: 1; }
    75% { opacity: 0.8; }
  }
  
  @keyframes bounce-infinite {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
  }
  
  @keyframes wave-infinite {
    0%, 100% { transform: scale(1.1); }
    25% { transform: scale(1.15) rotate(5deg); }
    50% { transform: scale(1.2); }
    75% { transform: scale(1.15) rotate(-5deg); }
  }
  
  @keyframes rings-infinite {
    0% { transform: scale(1.1); opacity: 1; }
    50% { transform: scale(1.5); opacity: 0; }
    51% { transform: scale(1.1); opacity: 1; }
    100% { transform: scale(1.1); opacity: 1; }
  }
  /* "Эффекты для рангов 2" */